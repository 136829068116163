<template>
  <div class="itemBox">
    <div class="avatar">
      <!-- <el-avatar
        shape="square"
        :size="50"
        src="https://polyjetclub-backend.polysmartchain.com/images/normal/866.png"
      ></el-avatar> -->
      <div class="wl-avatar">
        {{ uti.setAvatar(item.nickName) }}
      </div>
    </div>
    <div class="user">
      <div class="name">{{ item.nickName }}</div>
      <div class="addr">{{ item.address }}</div>
    </div>
    <div v-if="followed == 1" class="but2" @click="delGZ()">已关注</div>
    <div v-else class="but" @click="addGZ()">关注</div>
  </div>
</template>
<script>
import Store from "../../../store";
export default {
  components: {},
  props: {
    item: {
      type: Object,
    },
    followed: {
      type: Number,
      default() {
        return 1;
      },
    },
  },
  data() {
    return {
      store: Store.state,
      uti: Store.uti,
      it: {},
    };
  },
  methods: {
    // 添加关注
    async addGZ() {
      let { data: res } = await this.$http.post("/userFollow/follow", {
        targetUserId: this.item.id,
        userId: this.store.user.userId,
      });
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      this.$emit("shuax");
      // this.followed = 1;
      this.$message.success("关注成功");
    },
    // 取消关注
    async delGZ() {
      let { data: res } = await this.$http.post("/userFollow/cancelFollow", {
        targetUserId: this.item.id,
        userId: this.store.user.userId,
      });
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      this.$emit("shuax");
      // this.followed = 0;
      this.$message.success("取消关注成功");
    },
  },
};
</script>

<style lang="less" scoped>
.itemBox {
  display: flex;
  justify-content: space-between;
  height: 84px;
  align-items: center;
  border-bottom: 2px solid #d8d8d8;
  .user {
    margin-left: 16px;
    flex: 1;
    .name {
      /* 金牌讲师 */

      height: 23px;
      font-family: SourceHanSansCN-Regular;
      font-size: 16px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #333333;
    }
    .addr {
      /* 0Xd4a65s4d56a4sd65sad889a7dsad4as56dasd */

      height: 20px;
      font-family: SourceHanSansCN-Regular;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #999999;
    }
  }
  .but {
    /* 矩形 23 */
    width: 110px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 5px;
    background: #ff7d41;
    /* 关注 */
    font-family: SourceHanSansCN-Regular;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0em;
    color: #ffffff;
  }
  .but2 {
    /* 矩形 23 */

    width: 110px;
    height: 50px;

    line-height: 50px;
    text-align: center;
    border-radius: 5px;
    box-sizing: border-box;
    border: 1px solid #ff7d41;
    /* 已关注 */

    font-family: SourceHanSansCN-Regular;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0em;
    color: #ff7d41;
  }
}
</style>
