<template>
  <div class="viewBox">
    <!-- <div class="link"></div> -->
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="关注" name="first"> </el-tab-pane>
      <el-tab-pane label="粉丝" name="second"> </el-tab-pane>
    </el-tabs>
    <box-1-item
      @shuax="getdata()"
      :item="item"
      :followed="item.followed"
      v-for="(item, index) in store.gzFsList"
      :key="index"
    ></box-1-item>

    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page.sync="pageNum"
      :page-size="pageSize"
      layout="total, prev, pager, next"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
import Store from "../../../store";
import box1Item from "./box1-item.vue";
export default {
  components: { box1Item },
  mounted() {
    // 获取粉丝列表
    // this.getFSlist();
    // 获取关注列表
    this.getGZlist();
    // console.log("123456");
  },
  data() {
    return {
      activeName: "first",
      store: Store.state,
      uti: Store.uti,
      act: 0,

      total: 0,
      pageNum: 1,
      pageSize: 10,
    };
  },
  methods: {
    // 选中了某一项
    handleClick(tab) {
      this.act = tab.index;
      this.getdata();
    },
    // 获取数据
    getdata() {
      if (this.act == 0) {
        this.getGZlist();
      } else {
        this.getFSlist();
      }
    },
    // 获取我的粉丝
    async getFSlist() {
      let { data: res } = await this.$http.post(`/userFollow/followMe`, {
        pageNum: 1,
        pageSize: 1000,
        userId: this.store.user.userId,
      });
      if (res.code !== 200) {
        return this.$message.error(res.message);
      }
      this.total = Number(res.data.total);
      this.store.gzFsList = res.data.records;
    },
    // 获取我的关注
    async getGZlist() {
      let { data: res } = await this.$http.post(`/userFollow/myFollowUser`, {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        userId: this.store.user.userId,
      });
      if (res.code !== 200) {
        return this.$message.error(res.message);
      }
      this.total = Number(res.data.total);
      this.store.gzFsList = res.data.records;
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.getdata();
    },
  },
};
</script>

<style lang="less" scoped>
.viewBox {
  .el-pagination {
    margin-top: 20px;
  }
}
</style>
